<template>
  <div>
    <breadcrumb :items="breadcrumbs" />
    <hero>
      New Company
      <router-link 
        slot="right" 
        to="/companies"
        class="button"
      >
        Companies
      </router-link>
    </hero>
    <section class="section is-main-section">
      <card
        title="Add Company"
        icon="plus"
      >
        <validation-observer 
          v-slot="{ handleSubmit }"
          ref="form"
          tag="form"
        >
          <input-with-validation
            v-model="form.name"
            name="name"
            rules="required|max:255"
            label="Name"
            icon="nintendo-game-boy"
            maxlength="255"
            horizontal
          />
          <hr>
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button 
                  type="is-primary"
                  :loading="loading"
                  @click="handleSubmit(createCompany)"
                >
                  Submit
                </b-button>
              </div>
            </b-field>
          </b-field>
        </validation-observer>
      </card>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import Card from "@/components/Card";
import InputWithValidation from "@/components/Validation/InputWithValidation";

export default {
  name: 'CompaniesCreate',

  components: {
    ValidationObserver,
    Breadcrumb,
    Hero,
    Card,
    InputWithValidation,
  },

  data() {
    return {   
      breadcrumbs: [
        'Admin', 
        'Create Company',
      ],
      loading: false,
      form: {
        name: '',
      },
    };
  },

  methods: {
    ...mapActions('data', ['addCompany']),

    createCompany() {
      this.loading = true;
      this.addCompany(this.form).then(response => {
        this.$buefy.toast.open({
          message: 'Created successfully',
          type: 'is-success',
          position: 'is-bottom-right',
        });
        this.$router.push({ 
          name: 'CompaniesEdit', 
          params: { company: response.id },
        });
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
        if (err.response) {
          this.$refs.form.setErrors(err.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
